<template>
  <div id="productNoLanguage" class="container-fluid m-0 p-0">
    <div class="col-12 col-lg-12 m-0 p-0 shadow mb-3 bg-white rounded">
      <div class="row mx-0 px-0">
        <div class="col-12 col-xl-6 mb-3 mt-3">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend input-group-sm">
              <select class="custom-select" v-model="mode">
                <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                  {{ e.name }}
                </option>
              </select>
            </div>
            <input
              type="search"
              class="form-control form-control-sm"
              placeholder="ค้นหา / Seach"
              v-model="find"
              v-on:keyup.enter="Search()"
            />
            <div class="input-group-append">
              <button class="btn bt-main" @click="Search()">ค้นหา</button>
            </div>
            <div class="input-group-append">
              <button class="ml-3 btn bt-BNB" @click="export_excel">
                นำข้อมูลออก <span v-html="$svg_icon.excel"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 px-0 justify-content-center">
      <div class="col-12 col-lg-12 m-0 p-0 shadow mb-3 bg-white rounded">
        <div class="row m-0 py-4 px-2">
          <p class="px-3 font-600 w-100" style="display: flex">
            สินค้า&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ
          </p>
          <div class="col-12">
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              :current-page="page_num"
              :per-page="page_size"
              hover
              outlined
              show-empty
              responsive
              :busy="loading"
              class="font-0-8s"
            >
              <template #table-busy>
                <div class="text-center text-main my-2">
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(shortDesc1TH)="row">
                <p v-html="row.item.shortDesc1TH"></p>
              </template>
              <template #cell(shortDesc2TH)="row">
                <p v-html="row.item.shortDesc2TH"></p>
              </template>
              <template #cell(shortDesc1EN)="row">
                <p v-html="row.item.shortDesc1EN"></p>
              </template>
              <template #cell(shortDesc2EN)="row">
                <p v-html="row.item.shortDesc2EN"></p>
              </template>
              <template #cell(longDescTH)="row">
                <p v-html="row.item.longDescTH"></p>
              </template>
              <template #cell(longDescEN)="row">
                <p v-html="row.item.longDescEN"></p>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-6 col-xl-3 input-group-prepend input-group-sm">
                <select
                  class="custom-select"
                  v-model="page_size"
                >
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3">
                <b-pagination
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableProductNoLanguage",
  data() {
    return {
      items: [],
      itemlist: [],
      find: "",
      loading: false,
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      totalRows: 0,
      mode: "skCode",
      select_mode: [
        { name: "ค้นหาด้วย Skcode", key: "skCode" },
        { name: "ค้นหาด้วยชื่อ ภาษาอังกฤษ", key: "prNameEN" },
      ],
      fields: [
        { key: "skCode", label: "skCode", class: "text-left" },
        { key: "prNameEN", label: "prNameEN", class: "text-left" },
        { key: "shortDesc1EN", label: "shortDesc1EN", class: "text-left" },
        {
          key: "longDescEN",
          label: "longDescEN",
          class: "text-left",
        },

        {
          key: "attrDataEN",
          label: "attrDataEN",
          class: "text-left",
        },
      ],
    };
  },
  watch: {
    page_num: function () {},
    find: function () {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },
  computed: {
     getPimProductNoLanguage: function () {
      return this.$store.getters["UploadEnActions/GET_PROD_DATA"];
    },
  },
  watch: {
    getPimProductNoLanguage: function (v) {
      this.items = v;
      this.itemlist = v;
      this.totalRows = v.length;
    },
  },
  methods: {
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    // async getPimProductNoLanguage() {
    //   let getAPI = await this.$store.getters["UploadEnActions/GET_PROD_DATA"];
    //   this.items = getAPI.data;
    //   this.itemlist = getAPI.data;
    //   this.totalRows = getAPI.data.length;
    //   try {
    //   } catch (error) {
    //     this.loading = false;
    //     this.$serviceMain.showErrorAlert(
    //       this,
    //       error.message == undefined ? error : error.message
    //     );
    //   }
    // },
    async export_excel() {
      const data = [];
      let getData = await this.$serviceAPI.call_API(
        "post",
        `manage/exportExcelProductNoLanguage`,
        data,
        1
      );
      let name = `ProductPim_EN.xlsx`;
      this.$serviceMain.JsonToExcat(getData.data, name);
      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async Search() {
      var results = [];
      if (this.find == "") {
        this.unSearch();
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("UploadEnActions/getPimProductNoLanguage");
    // await this.getPimProductNoLanguage();
  },
};
</script>

<style></style>
