<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Upload EN Language</p>
          </div>
        </div>
        <div class="row m-0 p-0 w-100">
          <div class="col-12">
            <b-tabs v-model="tabIndex" content-class="mt-1 p-3">
              <b-tab title="Monitoring" :title-link-class="linkClass(0)">
                <TableProductsLanguage />
              </b-tab>
              <b-tab title="Upload" :title-link-class="linkClass(1)">
                <UploadPimDataMultiLanguage />
              </b-tab>
              <b-tab
                title="Product UnUpdate EN"
                :title-link-class="linkClass(2)"
              >
                <TableProductNoLanguage />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPimDataMultiLanguage from "../../components/Manage/ManagePimProduct/UploadPimData.vue";
import TableProductsLanguage from "../../components/Manage/ManagePimProduct/TableProductsLanguage.vue";
import TableProductNoLanguage from "../../components/Manage/ManagePimProduct/TableProductNoLanguage.vue";

export default {
  name: "ManagePimData",
  components: {
    UploadPimDataMultiLanguage,
    TableProductsLanguage,
    TableProductNoLanguage,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  mounted() {
    // this.$store.dispatch("UploadEnActions/getPimProductLanguage");

  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["text-main", "font-weight-bold"];
      } else {
        return ["text-muted"];
      }
    },
  },
};
</script>

<style></style>
