<template>
  <div id="productDetail" class="container-fluid m-0 p-0">
    <div class="col-12 col-lg-12 m-0 p-0 shadow mb-3 bg-white rounded">
      <div class="row mx-0 px-0">
        <div class="col-12 col-xl-6 mb-3 mt-3">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend input-group-sm">
              <select class="custom-select" v-model="mode">
                <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                  {{ e.name }}
                </option>
              </select>
            </div>
            <input
              type="search"
              class="form-control form-control-sm"
              placeholder="ค้นหา / Seach"
              v-model="find"
              v-on:keyup.enter="Search()"
            />
            <div class="input-group-append">
              <button class="btn bt-main" @click="Search()">ค้นหา</button>
            </div>
            <button
              type="button"
              class="ml-3 btn-sm btn bt"
              @click="InterfaceData()"
            >
              เชื่อมต่อข้อมูล
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 px-0 justify-content-center">
      <div class="col-12 col-lg-12 m-0 p-0 shadow mb-3 bg-white rounded">
        <div class="row m-0 py-4 px-2">
          <p class="px-3 font-600 w-100" style="display: flex">
            สินค้า&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ
          </p>
          <div class="col-12">
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              :current-page="page_num"
              :per-page="page_size"
              hover
              outlined
              show-empty
              responsive
              :busy="loading"
              class="font-0-8s"
            >
              <template #table-busy>
                <div class="text-center text-main my-2">
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(shortDesc1TH)="row">
                <p v-html="row.item.shortDesc1TH"></p>
              </template>
              <template #cell(shortDesc2TH)="row">
                <p v-html="row.item.shortDesc2TH"></p>
              </template>
              <template #cell(shortDesc1EN)="row">
                <p v-html="row.item.shortDesc1EN"></p>
              </template>
              <template #cell(shortDesc2EN)="row">
                <p v-html="row.item.shortDesc2EN"></p>
              </template>
              <template #cell(longDescTH)="row">
                <p v-html="row.item.longDescTH"></p>
              </template>
              <template #cell(longDescEN)="row">
                <p v-html="row.item.longDescEN"></p>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-6 col-xl-3 input-group-prepend input-group-sm">
                <select
                  class="custom-select"
                  v-model="page_size"
                >
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3">
                <b-pagination
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableProductsLanguage",
  data() {
    return {
      items: null,
      itemlist: null,
      totalRows: 0,
      find: "",
      page_size: 10,
      loading: false,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,

      mode: "skCode",
      select_mode: [
        { name: "ค้นหาด้วย Skcode", key: "skCode" },
        { name: "ค้นหาด้วย ชื่อภาษาอังกฤษ", key: "prNameEN" },
        { name: "ค้นหาด้วย สถานะ", key: "status" },
      ],
      fields: [
        { key: "skCode", label: "skcode", class: "text-left" },
        { key: "prNameEN", label: "prNameEN", class: "text-left" },
        { key: "shortDesc1EN", label: "shortDesc1EN", class: "text-left" },
        // },
        {
          key: "longDescEN",
          label: "longDescEN",
          class: "text-left",
        },

        {
          key: "attrDataEN",
          label: "attrDataEN",
          class: "text-left",
        },
        {
          key: "lastUpdate",
          label: "LastUpdate",
          class: "text-left",
        },
        {
          key: "status",
          label: "Status",
          class: "text-left",
        },
      ],
    };
  },
  watch: {
    page_num: function() {},
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
    // getPimProductLanguage: function (v) {
    //   this.items = v;
    //   this.itemlist = v;
    //   this.totalRows = v.length;
    // },
  },
  computed: {
    // getPimProductLanguage: function () {
    //   return this.$store.getters["UploadEnActions/GET_PIM_DATA"];
    // },
  },
  mounted() {
    this.getPimProductLanguage();
  },
  methods: {
    async getPimProductLanguage() {
      try {
        this.loading = true;
        await this.$store.dispatch("UploadEnActions/getPimProductLanguage");
        this.items = this.$store.getters["UploadEnActions/GET_PIM_DATA"];
        this.itemlist = this.$store.getters["UploadEnActions/GET_PIM_DATA"];
        this.totalRows = this.items.length;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        this.getPimProductLanguage();
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
          // }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
    async InterfaceData() {
      try {
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `คุณต้องการเชื่อมต่อข้อมูลหรือไม่? / Do you want to connect data?`
        );
        const data = [];
        if (confirm) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `manage/interfacePimLanguage`,
            data,
            1
          );
          await this.getPimProductLanguage();
          await this.$store.dispatch("UploadEnActions/getPimProductNoLanguage");
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>
